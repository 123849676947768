<template>
    <div>
        <el-form inline :model="editForm" label-width="140px" style="margin-top: 0px;">
            <el-form-item label="题目内容">
                <el-input style="width:500px;" v-model="editForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否随机">
                <el-switch v-model="editForm.isRandom" active-text="是" inactive-text="否"></el-switch>
            </el-form-item>
            <el-form-item label="是否资源">
                <el-switch v-model="editForm.isSource" active-text="是" inactive-text="否"></el-switch>
            </el-form-item>
            <el-form-item label="触点分类">
                <el-select v-model="editForm.cateId" clearable @change="onQCateChange" placeholder="请选择">
                    <el-option
                    v-for="item in cateList2024"
                    :key="item.cateId"
                    :label="item.cateName"
                    :value="item.cateId">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item v-if="editForm.typeId == 2 || editForm.typeId == 5 || editForm.typeId == 7" :label="editForm.typeId == 7?'最低分':'最小数量'">
                <el-input-number v-model="editForm.minNum" :min="1" :max="999" label="描述文字"></el-input-number>
            </el-form-item> -->
            <el-form-item v-if="editForm.typeId == 2 || editForm.typeId == 5 || editForm.typeId == 7" :label="editForm.typeId == 7?'最高分':'最大数量(999:不限)'">
                <el-input-number v-model="editForm.maxNum" :min="1" :max="999" label="描述文字"></el-input-number>
            </el-form-item>

            <div v-if="editForm.typeId == 1 || editForm.typeId == 2 || editForm.typeId == 5">                
                <div style="margin-top:10px;" v-for="(item,index) in editForm.answers" :key="index">
                    <el-form-item label="选项">
                        <el-input style="width:200px;" v-model="item.answer" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="触点分类">
                        <el-select v-model="item.cateId" clearable @change="(val)=>{onCateChange(val, item)}"
                         placeholder="请选择">
                            <el-option
                            v-for="itemc in cateList"
                            :key="itemc.cateId"
                            :label="itemc.cateName"
                            :value="itemc.cateId">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item style="margin-left:-40px;" label="输入项">
                        <el-switch v-model="item.fillOther" active-text="是" inactive-text="否"></el-switch>
                    </el-form-item>
                    <!-- <el-form-item style="margin-left:-40px;" label="结束问卷">
                        <el-switch v-model="item.isOver" active-text="是" inactive-text="否"></el-switch>
                    </el-form-item> -->
                    <el-form-item label="">
                        <el-tooltip class="item" effect="dark" content="添加" placement="bottom">
                            <i style="color:#82C0FF;" class="el-icon-circle-plus optBtn" @click="onAdd(index, true)"></i>
                        </el-tooltip>
                        <el-tooltip v-if="editForm.answers.length > 1" class="item" effect="dark" content="删除" placement="top">
                            <i style="color:red;" class="el-icon-delete optBtn" @click="onDelete(index, true)"></i>
                        </el-tooltip>
                        <el-tooltip v-if="index > 0" class="item" effect="dark" content="上移" placement="bottom">
                            <i style="color:#82C0FF;" class="el-icon-upload2 optBtn" @click="onSwap(index-1, index, true)"></i>
                        </el-tooltip>
                        <el-tooltip v-if="index < editForm.answers.length - 1" class="item" effect="dark" content="下移" placement="top">
                            <i style="color:#82C0FF;" class="el-icon-download optBtn" @click="onSwap(index, index+1, true)"></i>
                        </el-tooltip>
                    </el-form-item>
                    <!-- <i style="color:#82C0FF;" class="el-icon-circle-plus optBtn" @click="onAdd(index, true)"></i> -->     
                    <div style="margin-left:20px;margin-top:-10px;padding-bottom: 8px;">            
                        <span v-if="item.relation.length>0 && item.relation[0].qIndex!=''" style="color:#82C0FF;cursor:pointer;" @click="onOpenSetConnect(index)">                        
                            <br/>
                            <span v-for="(itemR,indexR) in item.relation" :key="indexR">                            
                                <span v-if="itemR.qIndex!=''">
                                    <span style="font-weight: bold;">第{{itemR.qIndex*1+1}}题</span>
                                    <span v-if="itemR.aIndexs.length>0">
                                        <span v-for="(itemRA,indexRA) in itemR.aIndexs" :key="indexRA">
                                            <span v-if="indexRA>0">、</span><span v-else>第</span>{{itemRA*1+1}}项
                                        </span>
                                    </span>
                                    <span v-if="itemR.cIndexs.length>0">
                                        <span v-for="(itemRC,indexRC) in itemR.cIndexs" :key="indexRC">
                                            <span v-if="indexRC>0">、</span><span v-else>第</span>{{itemRC*1+1}}列
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span v-else style="color:#82C0FF;cursor:pointer;" @click="onOpenSetConnect(index)">                        
                            设置内容关联
                        </span>
                    </div>   
                </div>
            </div>
            <!-- 矩阵 -->
            <div v-if="editForm.typeId == 4" style="display:flex;margin-top:10px;">
                <div style="width:65%;padding-right:0.2rem;">
                    行
                    <div v-for="(item,index) in editForm.answers" :key="index" :style="index % 2 == 0?'background-color: #F2F2F2;padding:5px;':'padding:5px;'">
                        <el-form-item label="选项" label-width="30px;">
                            <el-input v-model="item.answer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-left:-40px;" label="输入项">
                            <el-switch v-model="item.fillOther" active-text="是" inactive-text="否"></el-switch>
                        </el-form-item>
                        <!-- <el-form-item label="分类">
                            <el-select style="width:120px;" v-model="item.cateId" clearable @change="(val)=>{onCateChange(val, item)}"  placeholder="请选择">
                                <el-option
                                v-for="itemc in cateList2024"
                                :key="itemc.cateId"
                                :label="itemc.cateName"
                                :value="itemc.cateId">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        
                        <el-form-item label="">
                            <el-tooltip class="item" effect="dark" content="添加" placement="bottom">
                                <i style="color:#82C0FF;" class="el-icon-circle-plus optBtn" @click="onAdd(index, true)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="editForm.answers.length > 1" class="item" effect="dark" content="删除" placement="top">
                                <i style="color:red;" class="el-icon-delete optBtn" @click="onDelete(index, true)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="index > 0" class="item" effect="dark" content="上移" placement="bottom">
                                <i style="color:#82C0FF;" class="el-icon-upload2 optBtn" @click="onSwap(index-1, index, true)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="index < editForm.answers.length - 1" class="item" effect="dark" content="下移" placement="top">
                                <i style="color:#82C0FF;f" class="el-icon-download optBtn" @click="onSwap(index, index + 1, true)"></i>
                            </el-tooltip>
                        </el-form-item>
                        <!-- <span style="color:#82C0FF;cursor:pointer;" @click="onOpenSetConnect(index)">设置内容关联</span> -->
                        <div style="margin-left:20px;margin-top:-10px;padding-bottom: 8px;">       
                            <span v-if="item.relation.length>0 && item.relation[0].qIndex!=''" style="color:#82C0FF;cursor:pointer;" @click="onOpenSetConnect(index)">
                                <br/>
                                <span v-for="(itemR,indexR) in item.relation" :key="indexR">                            
                                    <span v-if="itemR.qIndex!=''">
                                        <span style="font-weight: bold;">第{{itemR.qIndex*1+1}}题</span>
                                        <span v-if="itemR.aIndexs.length>0">
                                            <span v-for="(itemRA,indexRA) in itemR.aIndexs" :key="indexRA">
                                                <span v-if="indexRA>0">、</span><span v-else>第</span>{{itemRA*1+1}}项
                                            </span>
                                        </span>
                                        <span v-if="itemR.cIndexs.length>0">
                                            <span v-for="(itemRC,indexRC) in itemR.cIndexs" :key="indexRC">
                                                <span v-if="indexRC>0">、</span><span v-else>第</span>{{itemRC*1+1}}列
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span v-else style="color:#82C0FF;cursor:pointer;" @click="onOpenSetConnect(index)">
                                设置内容关联
                            </span>
                        </div> 
                    </div>
                </div>
                <div style="width:30%;">
                    列
                    <div v-for="(item,index) in editForm.queCols" :key="index">
                        <el-form-item label="列名" label-width="30px;">
                            <el-input v-model="item.colName" autocomplete="off"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="">
                            <el-tooltip class="item" effect="dark" content="选择分值项" placement="bottom">
                                <i style="color:#82C0FF;" class="el-icon-search optBtn" @click="onOpenSelect(item)"></i>
                                <!-- <el-dropdown>
                                    <el-button type="primary">
                                        更多菜单<i class="el-icon-search el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>黄金糕</el-dropdown-item>
                                        <el-dropdown-item>狮子头</el-dropdown-item>
                                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                        <el-dropdown-item>双皮奶</el-dropdown-item>
                                        <el-dropdown-item>蚵仔煎</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> -->
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="添加" placement="bottom">
                                <i style="color:#82C0FF;" class="el-icon-circle-plus optBtn" @click="onAdd(index, false)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="editForm.queCols.length > 1" class="item" effect="dark" content="删除" placement="top">
                                <i style="color:red;" class="el-icon-delete optBtn" @click="onDelete(index, false)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="index > 0" class="item" effect="dark" content="上移" placement="bottom">
                                <i style="color:#82C0FF;" class="el-icon-upload2 optBtn" @click="onSwap(index-1, index, false)"></i>
                            </el-tooltip>
                            <el-tooltip v-if="index < editForm.queCols.length - 1" class="item" effect="dark" content="下移" placement="top">
                                <i style="color:#82C0FF;f" class="el-icon-download optBtn" @click="onSwap(index, index+1, false)"></i>
                            </el-tooltip>
                        </el-form-item>
                    </div>
                </div>
            </div>           
        </el-form>
        <div style="text-align:center;margin-top:20px;">
            <el-button @click="onSave(true)" type="primary">保存</el-button>
            <el-button @click="onSave(false)">关闭</el-button>
        </div>

        <el-dialog title="关联设置" v-model="setRelationVisible" width="60%"  
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="setRelationVisible"
                                    append-to-body>
            <connect :cur-index="compoIndex" :cur-ques="curQues.answers[curAIndex]" :can-use-ques="canUseQues"  :max-connect="false" @onConnectClose="closeConnect"/>
        </el-dialog>
        <el-dialog title="选择分值项" v-model="seleceScoreItemVisible" width="60%"  
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="seleceScoreItemVisible"
                                    append-to-body>
            <span class="radioDiv">
                <el-radio-group v-model="selRadio" @change="OnSelectScoreItem">
                    <el-radio v-for="(ri,riIndex) in radioItems" :label="ri">{{ri}}</el-radio>                
                </el-radio-group>
            </span>
            <div style="text-align: center;">
                <el-button type="danger" @click="seleceScoreItemVisible=false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template> 

<script>
import { mapGetters } from "vuex"; 
import { swapItems, getPreItemWithIndex } from "@/utils/tools";
import {getToken} from "@/utils/auth";
import connect from '@/views/Survey/bkground/component/connect';

export default {
    name: "EditContract",
    components: {
        connect
    },
    props: {
        'question': Object,
        'allQuestion': Object,
        'curIndex': Number
    },
    computed: {...mapGetters(['cateList','cateList2024'])},
    data() {
        return {
            editForm: {
                name: '',
                cateId: '',
                cateName: '',
                isRandom: false,
                isSource:false,
            },
            setRelationVisible: false,
            compoIndex: 0,
            compoAllQuestion: {},
            curAIndex: 0,
            seleceScoreItemVisible:false,
            itemForScore:{},
            radioItems:['5','非常多 5','非常多','非常长 5','非常长','非常同意'
                ,'4','比较同意','3','一般','2','比较不同意','1','非常少 1','非常少','非常短 1','非常短','不同意','非常不同意'],
            selRadio:'',
        }
    },
    watch:{
        question:{
            handler(newval){
                this.editForm = newval
                this.curQues = newval// item
                // console.log('change', newval)
            },
            deep:true,
            immediate:true
        },
        allQuestion:{
            handler(newval){
                this.compoAllQuestion = newval
                // console.log('change', newval)
            },
            deep:true,
            immediate:true
        },
        curIndex:{
            handler(newval){
                this.compoIndex = newval
                // console.log('change', newval)
            },
            deep:true,
            immediate:true
        }
    },
    async mounted() {			
       this.init()            
    },

    methods: {
        async init() {
            // if (this.modelId > 0){
            await this.$store.dispatch('survey/GetCateList',{userId:getToken(), typeId:1 }).then(()=>{  })
            await this.$store.dispatch('survey/GetCateList2024',{userId:getToken(), typeId:2 }).then(()=>{  })
            // this.editForm = this.question
            // console.log('this.editForm', this.editForm)
            // }
        },
        onOpenSelect(item){
            this.ItemForScore = item
            this.seleceScoreItemVisible=true
        },
        OnSelectScoreItem(){
            // console.log(this.selRadio)
            // return
            this.ItemForScore.colName = this.selRadio
            this.seleceScoreItemVisible = false
        },
        onQCateChange(para){
            // console.log('ssss',para)
            this.editForm.cateName = ''
            this.cateList.forEach(ele => {
                if (ele.cateId * 1 === para * 1) {
                    this.editForm.cateName = ele.cateName
                }
            });
        },
        onCateChange(para,item){
            // // console.log('ssss',para)
            // this.cateList.forEach(ele => {
            //     if (ele.cateId * 1 === para * 1) {
            //         this.editForm.cateName = ele.cateName
            //     }
            // });
            item.cateName = ''
            this.cateList.forEach(ele => {
                if (ele.cateId * 1 === para * 1) {
                    item.cateName = ele.cateName
                }
            });
        },
        onAdd(index,isQues) {
            // var pre = this.editForm.answers.filter((item,innerIndex) => index<=innerIndex)
            // var post = this.editForm.answers.filter((item,innerIndex) => index>innerIndex)
            var pre = []
            var post = []
            var all = []
            console.log('isQues', isQues)
            if (isQues) {
                all = JSON.parse(JSON.stringify(this.editForm.answers))
            } else {
                all = JSON.parse(JSON.stringify(this.editForm.queCols))
            }
            all.forEach((ele,loopindex) => {
                if (loopindex <= index) {
                    pre.push(ele)
                } else {
                    post.push(ele)
                }
            });
            // return
            // console.log(index, pre, post)
            if (isQues) {
                if (this.editForm.typeId == 4) {
                    pre.push({ aId: 0, qId: 0, answer: '',fillOther: false, isCol: false, subTypeId: 1, isOver: false
                    ,relation:[{qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: [], relationA: [], relationC: []}] })//,orderId: 2
                } else {
                    pre.push({ aId: 0, qId: 0, answer: '',fillOther: false, isCol: false, subTypeId: -1, isOver: false
                    ,relation:[{qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: [], relationA: [], relationC: []}] })//,orderId: 2
                }                
            } else {
                pre.push({ colId: 0, colName: ''})//,orderId: 2
            }
            if (isQues) {
                this.question.answers = pre.concat(post)
            } else {
                this.question.queCols = pre.concat(post)
            }
        },
        onDelete(index, isQues) {
            if (isQues) {
                this.editForm.answers.splice(index,1)
            } else {
                this.editForm.queCols.splice(index,1)
            }
        },
        onSwap(index1,index2, isQues) {
            if (isQues) {
                this.editForm.answers = swapItems(this.editForm.answers,index1, index2)
            } else {
                this.editForm.queCols = swapItems(this.editForm.queCols,index1, index2)
            }
        },
        onSave(isSave) {
            // console.log('onsave')
            if (isSave) {
                if (!this.editForm.name) {
                    this.$message.error("请填写题目内容");
                    return;
                }
                // if (!this.editForm.cateName) {
                //     this.$message.error("请选择触点分类");
                //     return;
                // }
                if (this.editForm.typeId == 2 || this.editForm.typeId == 5 || this.editForm.typeId == 7){
                    if (this.editForm.minNum > this.editForm.maxNum){
                        if (this.editForm.typeId == 7){
                            this.$message.error("最低分不得大于最低分！");
                        } else {
                            this.$message.error("最小数量不能高于最大数量！");
                        }                        
                        return;
                    }
                }
                var i = 0
                this.editForm.answers.forEach(ele => {
                    ele.index = i + ''
                    i ++
                });
                i = 0
                this.editForm.queCols.forEach(ele => {
                    ele.index = i + ''
                    i ++
                });
                if (this.editForm.typeId == 3 || this.editForm.typeId == 6 || this.editForm.typeId == 7){
                    this.editForm.answers = []
                }
                console.log('1',this.editForm.cateId)
                if (this.editForm.cateId){
                    var clist = this.cateList2024.find(item=>item.cateId===this.editForm.cateId)
                    console.log('2',clist)
                    if (clist){
                        this.editForm.cateName = clist.cateName
                        console.log('3',this.editForm.cateName)
                    }
                }
                // console.log('ques', this.editForm)
                this.$emit("close",this.editForm);
            } else {
                this.$emit('close',null)
            }
           
        },
        onOpenSetConnect(index){
            
            // console.log('item2',this.curQues.answers,this.curAIndex,this.curQues.answers[this.curAIndex])
            // this.curQues = this.editForm// item
            this.curAIndex = index
            var idx=this.compoIndex
            if (idx == -1) {
                idx = this.compoAllQuestion.questions.length
            }
            console.log('this.compoIndex',idx)
            this.canUseQues = getPreItemWithIndex(this.compoAllQuestion.questions, idx)
            console.log('aa', this.editForm.answers[this.curAIndex].relation)
            // console.log('this.canUseQues',this.canUseQues)
            this.setRelationVisible = true
        },
        closeConnect(obj){
            this.setRelationVisible = false
            // var aa = JSON.parse(JSON.stringify(this.editForm.answers[this.curAIndex].relation))
            // console.log('bb', aa)
            this.editForm.answers[this.curAIndex].relation = obj.relation // this.curQues
            //
            console.log('bb', this.editForm.answers[this.curAIndex].relation)
            // console.log('cc', this.editForm.answers[this.curAIndex])
            // console.log('ddd',this.editForm.answers)
            // localStorage.setItem('curModel', JSON.stringify(this.editForm))
        }
    }
}
</script>

<style scoped>
.radioDiv >>> .el-radio{
    line-height: 2rem;
}
.optBtn{
    font-size: larger;margin-left:8px;cursor:pointer;
}

</style>