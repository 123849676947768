<template>
    <div class="curContainer" style="background: #fff;">
        <div style="min-height:400px;">
            <el-form inline :model="editForm" label-width="140px" style="margin-top: 30px;">
                <div style="display:flex;">                    
                    <div style="width:100%;margin-top:20px;">
                        <el-form-item v-if="isModel === 'true'" style="margin-left:-50px;font-weight: bold;" label="模板名称">
                            <el-input v-model="editForm.modelName" autocomplete="off"></el-input>
                        </el-form-item>
                        
                        <div style="width:100%;padding:0px 8px;">
                            <div class="relaTable2" v-for="(ques,indexq) in editForm.questions" :key="indexq" style="">
                                <div v-if="ques" style="width:100%;display:flex;">
                                    <div style="width: 100%;">
                                        <div style="font-weight: bold;">
                                            {{ indexq+1 }}、{{ ques.name }}
                                            <span style="color:gray;">{{ques.typeId == 1?'[单选]' : ques.typeId == 2 ? '[多选]': ques.typeId == 3 ?'[填空]' :ques.typeId == 4 ? '[矩阵]' :ques.typeId == 5 ? '[排序]':''}} 
                                                <span v-if="ques.cateName">[{{ ques.cateName }}]</span>
                                                <span v-if="ques.isSource">[资源]</span>
                                            </span>
                                        </div>
                                        <div v-if="ques.typeId == 1">
                                            <div style="margin-left:30px;" v-for="(ans,indexa) in ques.answers" :key="indexa">
                                                <el-radio  v-model="ans.selected" :label="ans.aId">{{ ans.answer }}</el-radio><span style="color:grey;" v-if="ans.cateName">[{{ ans.cateName }}]</span>
                                                <span v-if="ans.fillOther">
                                                    <el-input style="width:200px;" v-model="ans.otherTextValue"  clearable></el-input>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="ques.typeId == 2 || ques.typeId == 5">
                                            <div style="margin-left:30px;" v-for="(ans,indexa) in ques.answers" :key="indexa">
                                                <el-checkbox  v-model="ans.selected" :label="ans.aId">{{ ans.answer }}</el-checkbox><span style="color:grey;" v-if="ans.cateName">[{{ ans.cateName }}]</span>
                                                <span v-if="ans.fillOther">
                                                    <el-input style="width:200px;" v-model="ans.otherTextValue"  clearable></el-input>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- 填空 -->
                                        <div v-if="ques.typeId == 3">
                                            <el-input  style="width:400px;margin-left:30px;" v-model="ques.seleValue"></el-input>
                                        </div>
                                        <!-- 矩阵 -->
                                        <div v-if="ques.typeId == 4">
                                            <table class="my-table">
                                                <tr style="font-weight: bold;">
                                                    <td ></td>
                                                    <td style="padding:0 7px;min-width:70px;text-align:center;" v-for="(itemcol,indexcol) in ques.queCols" :key="indexcol">{{ itemcol.colName }}</td>
                                                </tr>
                                                <tr v-for="(itemrow,indexrow) in ques.answers" :key="indexrow">
                                                    <td style="max-width:260px;">{{ itemrow.answer }}
                                                        <!-- <span style="color:grey;" v-if="itemrow.cateName">[{{ itemrow.cateName }}]</span> -->
                                                    </td>
                                                    <td style="text-align: center;" v-for="(itemcol,indexcol) in ques.queCols" :key="indexcol">
                                                        <el-radio label=" "></el-radio>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- 省份城市 -->
                                        <div v-else-if="ques.typeId == 6">
                                            <el-cascader  style="margin-left:30px;" :options="provinceCityList" ></el-cascader>
                                        </div>
                                        <!-- 打分题 -->
                                        <div v-else-if="ques.typeId == 7">
                                            <div style="display:flex;">
                                                <el-rate  style="margin-left:30px;" show-score v-model="ques.seleValue" :max="ques.maxNum"></el-rate><div style="margin-left:3px;">分</div>
                                            </div>
                                        </div>
                                        <div style="margin-left:40px;" v-if="ques.relation.length>0 && ques.relation[0].aIndexs.length>0">
                                            <span style="font-weight: bold;">关联({{ ques.logic?ques.logic:'或' }})&nbsp;&nbsp;</span>
                                            <span v-for="(itemR,indexR) in ques.relation" :key="indexR">
                                                <span style="font-weight: bold;">&nbsp;&nbsp;第{{itemR.qIndex*1+1}}题</span>
                                                <span v-if="itemR.aIndexs.length>0">
                                                    <span v-for="(itemRA,indexRA) in itemR.aIndexs" :key="indexRA">
                                                        <span v-if="indexRA>0">、</span><span v-else>第</span>{{itemRA*1+1}}项
                                                    </span>
                                                </span>
                                                <span v-if="itemR.cIndexs.length>0">
                                                    <span v-for="(itemRC,indexRC) in itemR.cIndexs" :key="indexRC">
                                                        <span v-if="indexRC>0">、</span><span v-else>第</span>{{itemRC*1+1}}列
                                                    </span>
                                                </span>
                                            </span>
                                        </div>                                        
                                    </div>
                                    <div style="width: 330px;display: flex;align-items: center;justify-content: center;">     
                                        <el-tooltip class="item" effect="dark" content="编辑" placement="bottom">
                                            <i style="color:#82C0FF;cursor:pointer;" class="el-icon-edit optBtn" @click="onQuesEdit(ques.typeId,indexq,-1)"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                            <i style="color:red;margin-left:5px;cursor:pointer;" class="el-icon-delete optBtn" @click="onQuesDelete(indexq)"></i>
                                        </el-tooltip>
                                        <el-tooltip v-if="indexq > 0" class="item" effect="dark" content="关联" placement="bottom">
                                            <i style="color:#82C0FF;margin-left:5px;cursor:pointer;" class="el-icon-paperclip optBtn" @click="onSetRelation(ques,indexq)"></i>
                                        </el-tooltip>
                                        <el-tooltip v-if="indexq > 0" class="item" effect="dark" content="上移" placement="top">
                                            <i style="color:#82C0FF;margin-left:5px;cursor:pointer;" class="el-icon-upload2 optBtn" @click="onSwap(indexq-1, indexq)"></i>
                                        </el-tooltip>
                                        <el-tooltip v-if="indexq < editForm.questions.length - 1" class="item" effect="dark" content="下移" placement="bottom">
                                            <i style="color:#82C0FF;margin-left:5px;cursor:pointer;" class="el-icon-download optBtn" @click="onSwap(indexq, indexq+1)"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="复制" placement="top">
                                            <i style="color:#82C0FF;cursor:pointer;" class="el-icon-document-copy optBtn" @click="onQuesCopy(ques,indexq)"></i>
                                        </el-tooltip>
                                        <el-dropdown style="color:#82C0FF;margin-left:5px;cursor:pointer;" class="user-name" trigger="click" @command="handleCommand">
                                            <span class="el-dropdown-link">
                                                新建
                                                <i class="el-icon-caret-bottom"></i>
                                            </span>
                                            <template #dropdown>
                                                <el-dropdown-item v-for="(item,index) in questionTypeList" :key="index" @click="onQuesEdit(item.dictId,-1,indexq)" divided>{{item.name}}</el-dropdown-item>
                                            </template>
                                        </el-dropdown>                                        
                                    </div>
                                </div>
                            </div>       
                            
                            <div style="padding: 5px;margin-top:20px;text-align: right;margin-right:70px;">
                                <el-dropdown style="color:#82C0FF;margin-left:5px;cursor:pointer;" class="user-name" trigger="click" @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        新建问题
                                        <i class="el-icon-caret-bottom"></i>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-item v-for="(item,index) in questionTypeList" :key="index" @click="onQuesEdit(item.dictId,-1,-1)" divided>{{item.name}}</el-dropdown-item>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </el-form>
            <div style="text-align:center;margin-top:20px;">
                <el-button :disabled="editForm.questions.length === 0" @click="onSave" type="primary">保存</el-button>
                <el-button @click="onMainClose">关闭</el-button>
            </div>
            <el-dialog title="题目设置" v-model="editModelVisible" width="80%"  
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="editModelVisible"
                                    append-to-body>
                <div>                    
                    <eachquestion @close="onClose" :question="curQues"  :all-question="editForm" :cur-index="curIndex"/>
                </div>
            </el-dialog>

            <el-dialog title="关联设置" v-model="setRelationVisible" width="60%"  
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="setRelationVisible"
                                    append-to-body>
                <connect :cur-index="curIndex" :cur-ques="curQues" :can-use-ques="canUseQues" :max-connect="true" @onConnectClose="closeConnect"/>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import eachquestion from '@/views/Survey/bkground/component/eachQuestion';
import connect from '@/views/Survey/bkground/component/connect';
import { DeleModel, SaveModel } from "@/api/survey";
import {getToken} from "@/utils/auth";
import { swapItems, getPreItemWithIndex } from "@/utils/tools";

export default {
    components: {
        eachquestion,connect
    },
    props: { 
        'isModel':{type:Boolean},
        'ef': Object
     },
    watch:{
        ef:{
            handler(newval){
                this.editForm = newval
                // console.log('change', newval)
            },
            deep:true,
            immediate:true
        }
    },
    computed: {...mapGetters(['questionTypeList','provinceCityList','modelDetail'])},
    data() {
        return {
            editForm: {
                modelName: '',
                questions: []
            },
            editModelVisible: false,
            curModel: {},
            typeId: '',
            curQues: {},
            curIndex: -1,
            setRelationVisible: false,
            canUseQues: [],
            relationA: [],
            relationC: [],
            fromPosition: -1
        };
    },
    async mounted() {
        await this.$store.dispatch('survey/GetProvinceCityList',{userId:getToken(), typeId:0 }).then(()=>{  })
        
        await this.onSearch(); 
        this.editForm = this.ef // JSON.parse(localStorage.getItem('curModel'))
    },
    methods: {
        async onSearch() {
            await this.$store.dispatch('survey/GetQuestionTypeList',{userId: getToken() }).then(()=>{ })
        },
        // // 编辑模板
        // async onModelEdit(item) {
        //     if (item == null) {
        //         this.editForm.modelId = 0
        //     } else {
        //         await this.$store.dispatch('survey/GetModelDetail',{userId: getToken(), modelId:item.modelId }).then(()=>{ 
        //             console.log('this.modelDetail',this.modelDetail)
        //             this.editForm = this.modelDetail
        //         })
        //     }            
        //     // this.isEdit = true
        // },
        // 编辑问题 当 index参数不是-1时 fromPosition:从哪个位置添加，如果是-1，则加到最后
        onQuesEdit(typeId,index,fromPosition) {
            if (typeId === 8){ // 关闭
                return
            }
            // console.log('aaa',typeId,index,fromPosition)
            this.typeId = typeId
            this.curIndex = index
            this.fromPosition = fromPosition
            if (index > -1) {
                this.curQues = this.editForm.questions[index]
                if (!this.curQues.logic){
                    this.curQues.logic = '或'
                }
            } else {
                var queCols = []
                var subTypeId = -1
                if (typeId == 4) {
                    queCols = [{colId: 0, colName: "列1"}, {colId: 0, colName: "列2"}]
                    subTypeId = 1
                }
                var answers = []
                if (typeId !==3 && typeId !==6 && typeId !== 7){
                    answers = [{ aId: 0, qId: 0, answer: '选项1',cateId: '', cateName: '', fillOther: false, isCol: false, subTypeId, isOver: false
                                ,relation:[{qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: [], relationA: [], relationC: []}] },//,orderId: 0
                            { aId: 0, qId: 0, answer: '选项2',cateId: '', cateName: '',fillOther: false, isCol: false, subTypeId, isOver: false
                                ,relation:[{qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: [], relationA: [], relationC: []}] }]
                }
                this.curQues = { qID: 0, name: '',cateId: '',cateName: '',logic: '或', typeId, minNum: 1, maxNum: 999, condition: '',// orderId: 0,
                        answers,//,orderId: 1
                        queCols,
                        relation:[{qId: -1, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: [], relationA: [], relationC: []}] 
                    }
            }
            this.editModelVisible = true
        },
        onQuesCopy(q,index){
            var obj = JSON.parse(JSON.stringify(q))
            obj.relation.forEach(ele => {
                ele.qIndex = ele.qIndex + ''
            });
            this.editForm.questions.splice(index+1,0,obj)// 指定位置添加
            this.onChangeInsertRelationOrder(index+1)
        },
        // async onDeleModel(item){      
        //     this.$confirm('确认删除['+item.modelName+'], 是否继续?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(async () => {
        //         var obj = {modelId:item.modelId,userId:getToken()}      
        //         await DeleModel(obj).then((res)=>{
        //             console.log('res',res)
        //             this.onSearch()                
        //         })
        //     }).catch(() => {
        //         // this.$message({
        //         //     type: 'info',
        //         //     message: '已取消删除'
        //         // });          
        //     });
        // },
        onQuesDelete(index) {
            this.$confirm('确认删除['+this.editForm.questions[index].name+'], 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                if (!this.onCheckQuestionCanDel(index)){
                    this.$message.error("存在关联关系，无法删除，可先移除关联关系");
                    return
                }
                this.editForm.questions.splice(index,1)
                this.onChangeDelRelationOrder(index)
                localStorage.setItem('curModel', JSON.stringify(this.editForm))
            })
            
        },
        onSwap(index1,index2) {
            // console.log('do swap')
            if (this.onCheckCanChange(index1,index2)){
                this.editForm.questions = swapItems(this.editForm.questions,index1, index2)
                this.onChangeSwapRelationOrder(index1,index2)
                localStorage.setItem('curModel', JSON.stringify(this.editForm))
            } else {
                this.$message.error("有前置关联关系，无法调整，可先删除关联关系后再做调整");
            }
            
        },
        onClose(ques){
            // console.log('aa', ques)
            this.editModelVisible = false
            if (ques != null){
                // console.log('ques', ques)
                if (this.curIndex > -1) {
                    this.editForm.questions[this.curIndex] = ques
                } else {
                    if (ques){
                        if (this.fromPosition == -1){
                            this.editForm.questions.push(ques) 
                        } else {
                            this.editForm.questions.splice(this.fromPosition+1,0,ques)// 指定位置添加
                            this.onChangeInsertRelationOrder(this.fromPosition+1)
                        }                    
                    }                
                }
                localStorage.setItem('curModel', JSON.stringify(this.editForm))
                // console.log('aa', this.editForm.questions)
            }
        },
        onCheckQuestionCanDel(index1){
            var can = true
            this.editForm.questions.forEach((ele,index) => {                
                var find = ele.relation.filter(function (item) {
                    return item.qIndex*1 == index1*1
                })
                if (find && find.length>0 && find[0].qIndex !== ''){
                    can = false
                }

                ele.answers.forEach(ele2 => {
                    var findA = ele2.relation.filter(function (item) {
                        return item.qIndex*1 == index1*1
                    })
                    if (findA && findA.length>0 && findA[0].qIndex !== ''){
                        can = false
                    }
                });
                
            })
            return can
        },
        onCheckCanChange(index1,index2){
            // console.log(this.editForm.questions[index2].relation,this.editForm.questions[index1])
            var preId = this.editForm.questions[index1].index*1
            var find = this.editForm.questions[index2].relation.filter(function (item) {
                    return item.qIndex*1 == preId
                })
            // console.log('find',find)
            if (find && find.length>0 && find[0].qIndex !== ''){
                return false
            } else {
                // return true
                this.editForm.questions[index2].answers.forEach(ele => {
                    var findA = ele.relation.filter(function (item) {
                        return item.qIndex*1 == preId
                    })
                    if (findA && findA.length>0 && findA[0].qIndex !== ''){
                        return false
                    }
                });
            }
            return true

        },
        // 交换题目位置后，调整关联题目顺序
        onChangeSwapRelationOrder(index1,index2){
            this.editForm.questions.forEach((ele,index) => {                
                if (index > index2) {// 调整项之后的所有问题
                    if (ele.relation.length>0){       
                        // console.log('rrr',JSON.parse(JSON.stringify(ele.relation)))                 
                        ele.relation.forEach(ele2 => {
                            if (ele2.qIndex*1 == index1*1){
                                // console.log('1',ele2.qIndex)
                                ele2.qIndex = index2+''
                                // console.log('11',ele2.qIndex)
                            }else  if (ele2.qIndex*1 == index2*1){
                                // console.log('2',ele2.qIndex , index1)
                                // console.log('2',ele2.qIndex)
                                ele2.qIndex = index1+''
                                // console.log('22',ele2.qIndex)
                            }
                        });
                        // console.log('rrr2',JSON.parse(JSON.stringify(ele.relation)))   
                    }

                    ele.answers.forEach(eleA => {
                        if (eleA.relation.length>0){       
                            // console.log('rrr',JSON.parse(JSON.stringify(ele.relation)))                 
                            eleA.relation.forEach(ele2 => {
                                if (ele2.qIndex*1 == index1*1){
                                    // console.log('1',ele2.qIndex)
                                    ele2.qIndex = index2+''
                                    // console.log('11',ele2.qIndex)
                                }else  if (ele2.qIndex*1 == index2*1){
                                    // console.log('2',ele2.qIndex , index1)
                                    // console.log('2',ele2.qIndex)
                                    ele2.qIndex = index1+''
                                    // console.log('22',ele2.qIndex)
                                }
                            });
                            // console.log('rrr2',JSON.parse(JSON.stringify(ele.relation)))   
                        }
                    });
                }
            });
        },
        // 插入题目后，调整关联题目顺序
        onChangeInsertRelationOrder(index1){
            this.editForm.questions.forEach((ele,index) => {                
                if (index > index1) {// 调整项之后的所有问题
                    if (ele.relation.length>0){   
                        ele.relation.forEach(ele2 => {
                            console.log('ele2',ele2,ele2.qIndex,index1)
                            if (ele2.qIndex !== '' && ele2.qIndex*1>=index1){
                                console.log('do')
                                ele2.qIndex = (ele2.qIndex*1 + 1) + ''
                            }                            
                        })
                    }
                    ele.answers.forEach(ele3 => {
                        if (ele3.relation.length>0){   
                            ele3.relation.forEach(ele4 => {
                                // console.log('ele2',ele2)
                                if (ele4.qIndex !== '' && ele4.qIndex*1>=index1){
                                    ele4.qIndex = (ele4.qIndex*1 + 1) + ''
                                }                            
                            })
                        }
                    });
                }
            })
        },
        // 删除题目后，调整关联题目顺序
        onChangeDelRelationOrder(index1){
            this.editForm.questions.forEach((ele,index) => {                
                if (index > index1) {// 调整项之后的所有问题
                    if (ele.relation.length>0){   
                        ele.relation.forEach(ele2 => {
                            // console.log('ele2',ele2)
                            if (ele2.qIndex !== '' && ele2.qIndex*1>index1){
                                ele2.qIndex = (ele2.qIndex*1 - 1) + ''
                            }                            
                        })
                    }

                    ele.answers.forEach(ele3 => {
                        if (ele3.relation.length>0){   
                            ele3.relation.forEach(ele4 => {
                                // console.log('ele2',ele2)
                                if (ele4.qIndex !== '' && ele4.qIndex*1>index1){
                                    ele4.qIndex = (ele4.qIndex*1 - 1) + ''
                                }                            
                            })
                        }
                    });
                }
            })
        },
        handleCommand(){
            console.log('aabba')
        },
        // getRelationLabel(item){
        //     var label = ""
        //     array.forEach(element => {
                
        //     });
        // },
        async onSave(){
            // console.log('aaa',this.editForm)
            // return;
            this.$emit('savemodel',this.editForm)
            // this.editForm.userId = getToken()
            // console.log(JSON.stringify(this.editForm))
            // await SaveModel(this.editForm).then((res)=>{
            //     // console.log('res',res)   
            //     this.onSearch()
            //     localStorage.removeItem('curModel') 
            //     this.isEdit = false 
            // })
        },
        onMainClose(){
            this.$emit('savemodel',null)
        },
        // 设置题目关联关系
        onSetRelation(item,index) {
            // console.log('item',item)
            this.curQues = item
            if (!this.curQues.logic){
                    this.curQues.logic = '或'
                }
            this.curIndex = index
            // var tmp = this.editForm.questions
            // this.canUseQues = tmp.slice(0,index)
            // console.log('aaaa',this.editForm.questions, index)
            this.canUseQues = getPreItemWithIndex(this.editForm.questions, index)
            // console.log('allqe', this.canUseQues)
            this.setRelationVisible = true
        },
        // // 关系设置中，切换题目时的处理
        // onRelationQChange(value,index) {
        //     // console.log('aaabbb',value, index, this.curQues.relation)
        //     var findQ = this.curQues.relation.filter(function (item) {
        //             return item.qIndex == value
        //         })
        //     // find(item => item.qIndex == value + '')
        //     // console.log('findQ', findQ)
        //     // console.log('bbb', this.curQues.relation,findQ.length)
        //     if (findQ.length>1) {
        //         this.curQues.relation[index].qIndex = ''
        //         this.$message.error('请勿重复加入相同的题目');
        //         return
        //     }
        //     this.curQues.relation[index].relationA = this.canUseQues[value].answers
        //     this.curQues.relation[index].relationC = this.canUseQues[value].queCols
        //     // this.canUseQues[value].aIndexs = []
        //     // this.canUseQues[value].cIndexs = []
        //     this.curQues.relation[index].aIndexs = []
        //     this.curQues.relation[index].cIndexs = []
        //     // console.log(this.relationA)
        // },
        // onRelationItemAdd() {
        //     this.curQues.relation.push({qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: []})
        // },
        // onRelationItemDelete(index) {
        //     this.curQues.relation.splice(index,1)
        // },
        // onRelationClose() {
        //     var checkOK = true
        //     this.curQues.relation.forEach(ele => {
        //         if (ele.qIndex!==''){
        //             if (ele.aIndexs.length == 0 || (ele.cIndexs.length==0 && ele.relationC.length>0)){
        //                 checkOK = false
        //             }
        //         }
        //     });
        //     if (!checkOK){
        //         this.$message.error("请将关联关系设置完整！");
        //         return
        //     }
        //     this.setRelationVisible=false
        //     this.editForm.questions[this.curIndex] = this.curQues
        //     localStorage.setItem('curModel', JSON.stringify(this.editForm))
        // }
        closeConnect(obj){
            this.setRelationVisible=false
            this.editForm.questions[this.curIndex] = obj // this.curQues
            localStorage.setItem('curModel', JSON.stringify(this.editForm))
        }
    }
}
</script>
 
<style  lang="scss">
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
    width: 100%;
}
.my-table td {
    border: 1px solid black;
}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #edf6ff;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
