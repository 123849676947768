<template>
    <div class="curContainer" style="background: #fff;">
        <div>                    
            <div style="margin-bottom: 20px;display: flex;">
                <span style="font-weight: bold;">当前题目{{ compoCurIndex + 1 }}:</span>
                <div style="margin-left:8px;">{{ compoQues.name ? compoQues.name : compoQues.answer }}</div>
            </div>
            <div>
                <span style="font-weight: bold;">关联关系：</span>
                <el-radio-group v-model="compoQues.logic">
                    <el-radio v-for="q in logicList" :key="q" :label="q" :value="q">{{q}}</el-radio>
                </el-radio-group>

                <!-- <el-select style="margin-left:8px;" v-model="compoQues.logic"
                            placeholder="请选择"
                            >
                            <el-option v-for="q in logicList" :key="q" :label="q" :value="q" ></el-option>
                        </el-select> -->

            </div>
            <el-divider></el-divider>
            <div class="relaTable" style="display: flex;justify-content: space-between;" v-for="(itemR,indexR) in compoQues.relation" :key="indexR">
                <div style="line-height: 2.3rem;width:100%;">
                    <div>
                        <span style="font-weight: bold;">关联题目:</span>
                        <el-select style="margin-left:8px;" v-model="itemR.qIndex"
                            placeholder="请选择"
                            @change="(val)=>{onRelationQChange(val, indexR)}"
                            >
                            <el-option v-for="q in compoCanUseQues" :key="q.index" :label="q.name" :value="q.index" :disabled="q.typeId == 3 || q.typeId == 6 || q.typeId == 7"></el-option>
                        </el-select>
                    </div>
                    <div v-if="itemR.qIndex && itemR.relationA.length > 0" style="display:flex;">
                        <div style="font-weight: bold;margin-left:20px;">关联问题:</div>
                        <div>
                            <el-checkbox-group style="margin-left:20px;"
                                v-model="itemR.aIndexs">
                                <el-checkbox v-for="a in itemR.relationA" :label="a.index" :key="a.index">{{a.answer}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div v-if="itemR.relationC && itemR.relationC.length>0" style="display:flex;background-color: #fff;">
                        <div style="font-weight: bold;margin-left:20px;">关联列:</div>
                        <div>
                            <el-checkbox-group style="margin-left:20px;"
                                v-model="itemR.cIndexs">
                                <el-checkbox v-for="a in itemR.relationC" :label="a.index" :key="a.index">{{a.colName}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div style="width:50px;display: flex;align-items: center;">                            
                    <i style="color:red;text-align: center;" class="el-icon-delete optBtn" @click="onRelationItemDelete(indexR)"></i>
                </div>
            </div>
            <div v-if="maxConnect || (!maxConnect && compoQues.relation.length === 0)" style="text-align: left;margin-top:10px;">
                <div style="color:#82C0FF;cursor:pointer;text-decoration: underline;" @click="onRelationItemAdd">新增关联</div>
            </div>
            <div style="text-align:center;margin-top:20px;">
                <el-button @click="onRelationClose">关闭</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        'curIndex':{type:Number},
        'canUseQues':{type:Array},
        'curQues': Object,
        'maxConnect': {type:Boolean}
    },
    data() {
        return {
            compoQues: {},
            compoCanUseQues: [],
            compoCurIndex: 0,
            logicList:['或','与']
        }
    },
    watch:{
        curIndex:{
            handler(newval){
                this.compoCurIndex = newval
                console.log('curIndex change', newval)
            },
            deep:true,
            immediate:true
        },
        curQues:{
            handler(newval){
                this.compoQues = JSON.parse(JSON.stringify(newval))
                // console.log('connect change', newval)
            },
            deep:true,
            immediate:true
        },
        canUseQues:{
            handler(newval){
                this.compoCanUseQues = newval
                console.log('compoCanUseQues', newval)
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        // 关系设置中，切换题目时的处理
        onRelationQChange(value,index) {
            var findQ = this.compoQues.relation.filter(function (item) {
                    return item.qIndex == value
                })
            if (findQ.length>1) {
                this.compoQues.relation[index].qIndex = ''
                this.$message.error('请勿重复加入相同的题目');
                return
            }
            this.compoQues.relation[index].relationA = this.compoCanUseQues[value].answers
            this.compoQues.relation[index].relationC = this.compoCanUseQues[value].queCols
            this.compoQues.relation[index].aIndexs = []
            this.compoQues.relation[index].cIndexs = []
        },
        onRelationItemAdd() {
            console.log('this.compoQues', this.compoQues)
            this.compoQues.relation.push({qId: 0, aId: 0, cId: 0,qIndex: '', aIndexs: [], cIndexs: []})
        },
        onRelationItemDelete(index) {
            this.compoQues.relation.splice(index,1)
        },
        onRelationClose() {
            var checkOK = true
            this.compoQues.relation.forEach(ele => {
                if (ele.qIndex!==''){
                    if (ele.aIndexs.length == 0 || (ele.cIndexs.length==0 && ele.relationC.length>0)){
                        checkOK = false
                    }
                }
            });
            if (!checkOK){
                this.$message.error("请将关联关系设置完整！");
                return
            }
            // console.log('aaa',this.compoQues)
            this.$emit("onConnectClose",this.compoQues);
            // this.setRelationVisible=false
            // this.editForm.questions[this.curIndex] = this.compoQues
            // localStorage.setItem('curModel', JSON.stringify(this.editForm))
        }
    }
}
</script>
<style  lang="scss">
</style>